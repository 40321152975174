import React, { useEffect } from "react"
 
function Support(props) {
    useEffect(() => {
        document.title = "Support - Mimeer"
    }, []);

  return (
    <div className="App">
        <header>
            <h1 className="App-Text">Support</h1>
        </header>
        <body>
            <p className="App-Text">
                To submit a question, bug report, feature request, 
                or otherwise contact the developer, please contact 
                me <a className="App-link" href="mailto:starlarddev@gmail.com">here</a>.
            </p>
        </body>
    </div>
  )
}
 
export default Support;