import React, { useEffect } from "react"
 
function Mimeer(props) {
    useEffect(() => {
        document.title = "Mimeer"
    }, []);

  return (
    <div className="App">
      <header>
        <h1 className="App-Text">Mimeer</h1>
      </header>
      <p className="App-Text">Coming Soon</p>
    </div>
  )
}
 
export default Mimeer;